import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

const DriversLicenseDropdown = ({ selectedDriversLicenses = [], setResumeData, setUnsavedChanges }) => {
    const { t } = useTranslation(); // Import translation function
    const [isOpenDL, setisOpenDL] = useState(false);
    const dropdownRefDL = useRef(null);

    // Fetch translated driver license options dynamically
    const options = [
        { key: "A", label: t("drivers_license_a") },
        { key: "A1", label: t("drivers_license_a1") },
        { key: "A2", label: t("drivers_license_a2") },
        { key: "AM", label: t("drivers_license_am") },
        { key: "B", label: t("drivers_license_b") },
        { key: "BE", label: t("drivers_license_be") },
        { key: "C", label: t("drivers_license_c") },
        { key: "C1", label: t("drivers_license_c1") },
        { key: "C1E", label: t("drivers_license_c1e") },
        { key: "CE", label: t("drivers_license_ce") },
        { key: "D", label: t("drivers_license_d") },
        { key: "D1", label: t("drivers_license_d1") },
        { key: "D1E", label: t("drivers_license_d1e") },
        { key: "DE", label: t("drivers_license_de") }
    ];

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        setResumeData((prevData) => {
            const currentDriversLicense = Array.isArray(prevData.personalData.driverslicense)
                ? prevData.personalData.driverslicense
                : [];

            const updatedDriversLicense = checked
                ? [...currentDriversLicense, value]
                : currentDriversLicense.filter((nat) => nat !== value);

            return {
                ...prevData,
                personalData: { ...prevData.personalData, driverslicense: updatedDriversLicense },
            };
        });

        setUnsavedChanges(true);
    };

    const handleToggleDropdownDL = () => {
        setisOpenDL(!isOpenDL);
    };

    return (
        <div className="custom-dropdown" style={{ marginBottom: "15px" }} ref={dropdownRefDL}>
            <div className="dropdown-header" onClick={handleToggleDropdownDL}>
                {selectedDriversLicenses.length > 0
                    ? selectedDriversLicenses.map((key) => options.find((opt) => opt.key === key)?.label || key).join(", ")
                    : t("select_drivers_license")}
                <span className="dropdown-arrow">▼</span>
            </div>
            {isOpenDL && (
                <div className="dropdown-list">
                    {options.map(({ key, label }) => (
                        <label key={key} className="dropdown-item">
                            <input
                                type="checkbox"
                                value={key}
                                checked={selectedDriversLicenses.includes(key)}
                                onChange={handleCheckboxChange}
                            />
                            {label}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DriversLicenseDropdown;
