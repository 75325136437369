import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import {useTranslation} from 'react-i18next';
import './Dashboard.css';
import logo from '../logo_light.png';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faTachometerAlt,
    faFileAlt,
    faPlus,
    faSignOut,
    faEdit,
    faTrash,
    faBriefcase
} from '@fortawesome/free-solid-svg-icons';

const Dashboard = () => {
    const {t, i18n} = useTranslation();
    const [cvs, setCvs] = useState([]);
    const token = localStorage.getItem('token');
    const navigate = useNavigate();

    const handleLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
    };

    useEffect(() => {
        const fetchCvs = async () => {
            if (token) {
                try {
                    const decodedToken = jwtDecode(token);
                    const userId = decodedToken.id;

                    const {data} = await axios.get(`https://backend.cvblok.com/cvs/list/${userId}`, {
                        headers: {Authorization: `Bearer ${token}`}
                    });

                    setCvs(data);
                } catch (error) {
                    console.error('Error fetching CVs:', error);
                    alert(t('error_fetching_cvs'));
                }
            } else {
                navigate('/');
            }
        };

        fetchCvs();
    }, [token, navigate, t]);

    const handleDelete = async (cvId) => {
        const confirmDelete = window.confirm(t('confirm_delete'));
        if (!confirmDelete) return;

        try {
            const token = localStorage.getItem("token");
            const decodedToken = jwtDecode(token);
            const userId = decodedToken.id;

            await axios({
                method: 'DELETE',
                url: `https://backend.cvblok.com/cvs/delete/${cvId}`,
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json"
                },
                data: {user_id: userId}
            });

            setCvs(prevCvs => prevCvs.filter(cv => cv.id !== cvId));
        } catch (error) {
            console.error('Fehler beim Löschen:', error.response?.data || error);
            alert(t('error_delete_cv'));
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString(t('locale'), {year: 'numeric', month: 'long', day: 'numeric'});
    };

    const [language, setLanguage] = useState(() => {
        return localStorage.getItem("language") || "tr"; // Get saved language or default to 'tr'
    });

    useEffect(() => {
        localStorage.setItem("language", language); // Save the selected language
    }, [language]);

// Update i18n language when state changes
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);  // Change language using i18n
    };

    const [activeSection, setActiveSection] = useState('dashboard'); // Default is the dashboard view
    const localeUrls = {
        de: "https://meine-berufserfahrung.de/overview.php?action=globallist&lang=de",
        en: "https://meine-berufserfahrung.de/overview.php?action=globallist&lang=en",
        tr: "https://meine-berufserfahrung.de/overview.php?action=globallist&lang=tr",
        es: "https://meine-berufserfahrung.de/overview.php?action=globallist&lang=es"
    };

    return (
        <div className="dashboard-wrapper">
            {/* Language Switcher */}
            <div className="language-switcher bottom">
    <span
        className={`language-flag ${language === 'tr' ? 'active' : ''}`}
        onClick={() => changeLanguage('tr')}
        role="button"
        aria-label="Türkçe"
    >
        🇹🇷
    </span>
                <span
                    className={`language-flag ${language === 'en' ? 'active' : ''}`}
                    onClick={() => changeLanguage('en')}
                    role="button"
                    aria-label="English"
                >
        🇬🇧
    </span>
                <span
                    className={`language-flag ${language === 'de' ? 'active' : ''}`}
                    onClick={() => changeLanguage('de')}
                    role="button"
                    aria-label="Deutsch"
                >
        🇩🇪
    </span>
                <span
                    className={`language-flag ${language === 'es' ? 'active' : ''}`}
                    onClick={() => changeLanguage('es')}
                    role="button"
                    aria-label="Español"
                >
        🇪🇸
    </span>
            </div>
            <aside className="sidebar">
                <div className="logo">
                    <img src={logo} alt="Logo" className="logo-image"/>
                </div>

                <Link to="/editor" className="new-cv-btn">
                    <FontAwesomeIcon icon={faPlus} className="menu-icon"/>
                    <span className="menu-text">{t('new_cv')}</span>
                </Link>

                <nav className="menu">
                    <ul>
                        <li className={`menu-item ${activeSection === 'dashboard' ? 'active' : ''}`} onClick={() => setActiveSection('dashboard')}>
                            <button>
                                <FontAwesomeIcon icon={faTachometerAlt} className="menu-icon"/>
                                <span className="menu-text">{t('dashboard')}</span>
                            </button>
                        </li>
                        <li className={`menu-item ${activeSection === 'cvs' ? 'active' : ''}`} onClick={() => setActiveSection('cvs')}>
                            <button>
                                <FontAwesomeIcon icon={faFileAlt} className="menu-icon"/>
                                <span className="menu-text">{t('cvs')}</span>
                            </button>
                        </li>
                        <li className={`menu-item ${activeSection === 'experience' ? 'active' : ''}`} onClick={() => setActiveSection('experience')}>
                            <button>
                                <FontAwesomeIcon icon={faBriefcase} className="menu-icon"/>
                                <span className="menu-text">{t('experience')}</span>
                            </button>
                        </li>
                    </ul>
                </nav>

                <Link className="logout-btn" onClick={handleLogout} to="#">
                    <FontAwesomeIcon icon={faSignOut} className="menu-icon"/>
                    <span className="menu-text">{t('logout')}</span>
                </Link>
            </aside>

            {/* Main Content */}
            <div className="dashboard-container">
                {activeSection === 'dashboard' && (
                    <>
                        <header className="dashboard-header">
                            <h1>{t('dashboard')}</h1>
                        </header>

                        <div className="resume-grid">
                            <Link to="/editor" className="resume-card new-cv">
                                <p>{t('create_new_cv')}</p>
                                <span>+</span>
                            </Link>
                            {cvs.map((cv) => (
                                <div key={cv.id} className="resume-card">
                                    {cv.personalData && (
                                        <div className="cv-preview">
                                            <div className="cv-preview-img-wrapper">
                                                {cv.personalData.photo ? (
                                                    <img
                                                        src={`https://backend.cvblok.com/${cv.personalData.photo}`}
                                                        alt="CV Preview"
                                                        className="cv-preview-img"
                                                    />
                                                ) : (
                                                    <div className="cv-preview-img-placeholder">{t('no_image')}</div>
                                                )}
                                            </div>

                                            <div className="cv-details">
                                                <h3 className="cv-name">
                                                    {cv.personalData.firstName} {cv.personalData.lastName}
                                                </h3>
                                                <p className="cv-slogan">{cv.personalData.slogan}</p>
                                                <p className="cv-last-updated">
                                                    <strong>{t('last_updated')}:</strong> {formatDate(cv.lastUpdated)}
                                                </p>
                                            </div>
                                        </div>
                                    )}

                                    <div className="button-group">
                                        <button className="delete-btn" onClick={() => handleDelete(cv.id)}>
                                            <FontAwesomeIcon icon={faTrash} style={{marginRight: 5}}/>
                                            <span className="small-text">{t('delete')}</span>
                                        </button>

                                        <Link to={`/editor/${cv.id}`} className="edit-link">
                                            <FontAwesomeIcon icon={faEdit} style={{marginRight: 5}}/>
                                            <span className="small-text">{t('edit')}</span>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                )}

                {activeSection === 'experience' && (
                    <>
                        <header className="dashboard-header">
                            <h1>{t('experience_title')}</h1>
                        </header>
                        <div className="experience-container">
                            <div className="experience-content">
                                <p className="experience-intro"
                                   dangerouslySetInnerHTML={{__html: t('experience_intro')}}></p>

                                <a href={localeUrls[i18n.language]} target="_blank" rel="noopener noreferrer"
                                   className="experience-link">
                                    {t('experience_link_text')}
                                </a>
                            </div>
                        </div>
                    </>
                )}

                {activeSection === 'cvs' && (
                    <>
                        <header className="dashboard-header">
                            <h1>{t('cvs')}</h1>
                        </header>
                        <div className="cvs-container">
                            {t('page_under_construction')}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default Dashboard;
