import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './LandingPage.css';
import logo from '../logo.png';
import {useNavigate} from 'react-router-dom';
import {Trans, useTranslation} from 'react-i18next';
import NotificationModal from "../components/NotificationModal";

const LandingPage = () => {
    const {t, i18n} = useTranslation();
    const [activeForm, setActiveForm] = useState('login');
    const [formData, setFormData] = useState({email: '', password: '', locale: i18n.language || 'tr'});
    const [showModal, setShowModal] = useState(false);
    const [userDetails, setUserDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        birthDate: ''
    });
    const [passwordPrompt, setPasswordPrompt] = useState(true); // Track if the password prompt should show
    const navigate = useNavigate();
    const [language, setLanguage] = useState(() => {
        return localStorage.getItem("language") || "tr"; // Get saved language or default to 'tr'
    });
    const [notification, setNotification] = useState({message: '', isOpen: false});

    useEffect(() => {
        localStorage.setItem("language", language); // Save the selected language
    }, [language]);

// Update i18n language when state changes
    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language, i18n]);

    const switchForm = (form) => setActiveForm(form);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleUserDetailsChange = (e) => {
        const {name, value} = e.target;
        setUserDetails({...userDetails, [name]: value});
    };

    const setAuthToken = (token) => {
        if (token) {
            axios.defaults.headers['Authorization'] = `Bearer ${token}`;
        } else {
            delete axios.defaults.headers['Authorization'];  // If token is not present, remove it
        }
    };

    // Use this function after login
    const handleSubmit = async () => {
        try {
            if (activeForm === 'register') {
                // Register logic
                const response = await axios.post('https://backend.cvblok.com/auth/register', formData);
                showNotification(t('registration_successful'));
                setActiveForm('login');
            } else if (activeForm === 'login') {
                // Regular login logic
                const {data} = await axios.post('https://backend.cvblok.com/auth/login', formData);
                localStorage.setItem('token', data.token);

                // Set token in axios defaults
                setAuthToken(data.token);

                navigate('/dashboard');  // Navigate directly to dashboard after successful login
            } else if (activeForm === 'loginWithMNH') {
                setShowModal(true);  // Show modal only for MNH login
            }
        } catch (error) {
            let errorMessage = '';
            if(error.response?.data?.message === 'Invalid credentials.'){
                errorMessage = t('invalidCredentials');
            }
            if(error.response?.data?.message === 'Email and password are required.'){
                errorMessage = t('emailAndPasswordAreRequired');
            }
            if(error.response?.data?.message === 'User not found.'){
                errorMessage = t('userNotFound');
            }
            if(error.response?.data?.message === 'Please activate your account first.'){
                errorMessage = t('pleaseActivateYourAccountFirst');
            }

            console.error('Error:', errorMessage || error.message);
            showNotification(errorMessage || 'An error occurred');
        }
    };


    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');

    const handleModalSubmit = async () => {
        try {
            if (activeForm === 'loginWithMNH') {
                const {data} = await axios.post('https://backend.cvblok.com/auth/login/mnh', {
                    mnhId: formData.mnhId,
                    firstName: userDetails.firstName,
                    lastName: userDetails.lastName,
                    email: userDetails.email,
                    birthDate: userDetails.birthDate,
                });

                if (data.token) {
                    localStorage.setItem('activationToken', data.activationToken);

                    console.log(data)
                    if (data.firstLogin) {
                        // Open password setup modal if it's the first login
                        setShowModal(false);
                        setIsPasswordModalOpen(true);
                    } else {
                        localStorage.setItem('token', data.token);
                        setAuthToken(data.token);
                        navigate('/dashboard');
                    }
                }
            }
        } catch (error) {
            console.error('MNH Login Error:', error);
            if (error.response && error.response.data) {
                showNotification(t('error_login_failed'));
            } else {
                showNotification(t('error_general'));
            }
        }
    };

    const handlePasswordSubmit = async () => {
        try {
            const activationToken = localStorage.getItem('activationToken');
            const {data} = await axios.post(
                'https://backend.cvblok.com/auth/set-password',
                {password: newPassword, activationToken: activationToken}
            );

            showNotification(t('password_set_success'));
            setIsPasswordModalOpen(false);

            localStorage.setItem('token', data.token);
            setAuthToken(data.token);

            navigate('/dashboard');
        } catch (error) {
            console.error('Error setting password:', error);
            showNotification(t('error_password_set'));
        }
    };


    const closeModal = () => {
        setShowModal(false);
    };


    const closePwModal = () => {
        setIsPasswordModalOpen(false);
    };

    const changeLanguage = (lang) => {
        setLanguage(lang);
        i18n.changeLanguage(lang);  // Change language using i18n

        setFormData((prevData) => ({
            ...prevData,
            locale: lang  // Update locale in formData
        }));
    };


    // Handle the password prompt
    useEffect(() => {
        const token = localStorage.getItem('token');
        // If there's no token and the password prompt hasn't been disabled yet
        if (!token && passwordPrompt) {
            const password = prompt('Giriş Kodu');

            if (password === 'blokcv!1') {  // Replace with your correct password
                setPasswordPrompt(false);  // Hide the prompt after correct password
            } else {
                showNotification('Kod yanlış. CVBlok, kariyerinizi şekillendirmenize yardımcı olmak için yakında sizlerle olacak!');
                window.location.reload();  // Reload page if the password is incorrect
            }
        }
    }, [passwordPrompt]);

    // Show Notification
    const showNotification = (messageKey) => {
        setNotification({message: t(messageKey), isOpen: true});
    };

    // Close Notification
    const closeNotification = () => {
        setNotification({message: '', isOpen: false});
    };

    return (
        <div className="landing-container">
            <div className="content">
                <div className="welcome-section">
                    <img src={logo} alt="CVBlok Logo" className="logo"/>
                    <h1>{t('welcome')}</h1>
                    <p className="teaser-slogan">
                        <Trans i18nKey="teaser_slogan" components={{br: <br/>}}/>
                    </p>
                </div>
                <div className="form-container">
                    {/* Language Switcher */}
                    <div className="language-switcher bottom">
    <span
        className={`language-flag ${language === 'tr' ? 'active' : ''}`}
        onClick={() => changeLanguage('tr')}
        role="button"
        aria-label="Türkçe"
    >
        🇹🇷
    </span>
                        <span
                            className={`language-flag ${language === 'en' ? 'active' : ''}`}
                            onClick={() => changeLanguage('en')}
                            role="button"
                            aria-label="English"
                        >
        🇬🇧
    </span>
                        <span
                            className={`language-flag ${language === 'de' ? 'active' : ''}`}
                            onClick={() => changeLanguage('de')}
                            role="button"
                            aria-label="Deutsch"
                        >
        🇩🇪
    </span>
                        <span
                            className={`language-flag ${language === 'es' ? 'active' : ''}`}
                            onClick={() => changeLanguage('es')}
                            role="button"
                            aria-label="Español"
                        >
        🇪🇸
    </span>
                    </div>


                    {activeForm === 'login' ? (
                        <div className="form login-form">
                            <h2>{t('login')}</h2>
                            <input type="email" name="email" placeholder={t('email')} value={formData.email}
                                   onChange={handleChange}/>
                            <input type="password" name="password" placeholder={t('password')} value={formData.password}
                                   onChange={handleChange}/>
                            <button onClick={handleSubmit} className="btn">{t('login')}</button>
                            <p onClick={() => switchForm('register')}
                               className="switch-link">{t('register_message')}</p>
                            <p onClick={() => setActiveForm('loginWithMNH')}
                               className="switch-link">{t('mnh_login')}</p>
                        </div>
                    ) : activeForm === 'loginWithMNH' ? (
                        <div className="form mnh-login-form">
                            <h2>{t('mnh_login')}</h2>
                            <input type="text" name="mnhId" placeholder="ID" value={formData.mnhId}
                                   onChange={handleChange}/>
                            <button onClick={handleSubmit} className="btn">{t('login')}</button>
                            <p onClick={() => switchForm('login')} className="switch-link">{t('normal_login')}</p>
                        </div>
                    ) : (
                        <div className="form register-form">
                            <h2>{t('register')}</h2>
                            <input type="email" name="email" placeholder={t('email')} value={formData.email}
                                   onChange={handleChange}/>
                            <input type="password" name="password" placeholder={t('password')} value={formData.password}
                                   onChange={handleChange}/>
                            <button onClick={handleSubmit} className="btn">{t('register')}</button>
                            <p onClick={() => switchForm('login')} className="switch-link">{t('already_registered')}</p>
                        </div>
                    )}
                </div>
            </div>

            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <span onClick={closeModal} className="close-btn">X</span>
                        <h3>{t('enter_personal_details')}</h3>
                        <p>{t('personal_details_message')}</p>
                        <input type="text" name="firstName" placeholder={t('first_name')} value={userDetails.firstName}
                               onChange={handleUserDetailsChange}/>
                        <input type="text" name="lastName" placeholder={t('last_name')} value={userDetails.lastName}
                               onChange={handleUserDetailsChange}/>
                        <input type="email" name="email" placeholder={t('email')} value={userDetails.email}
                               onChange={handleUserDetailsChange}/>
                        <input type="date" name="birthDate" placeholder={t('birth_date')} value={userDetails.birthDate}
                               onChange={handleUserDetailsChange}/>
                        <button onClick={handleModalSubmit}>{t('save_and_login')}</button>
                    </div>
                </div>
            )}

            {isPasswordModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span onClick={closePwModal} className="close-btn">X</span>
                        <h2>Passwort setzen</h2>
                        <input
                            type="password"
                            placeholder="Passwort eingeben"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <button onClick={handlePasswordSubmit}>Passwort speichern</button>
                    </div>
                </div>
            )}
            <NotificationModal message={notification.message} isOpen={notification.isOpen} onClose={closeNotification}/>
        </div>
    );
};

export default LandingPage;
