import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import axios from 'axios';
import {jwtDecode} from 'jwt-decode';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faSave,
    faArrowLeft,
    faDownload,
    faCalendar,
    faMapMarker,
    faEnvelope,
    faMars,
    faVenus,
    faPhone,
    faChevronDown,
    faChevronRight,
    faMapLocationDot,
    faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import './ResumeEditor.css';
import logo from '../logo.png';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import NationalityDropdown from "./NationalityDropdown";
import DriversLicenseDropdown from "./DriversLicenseDropdown";
import {useTranslation} from "react-i18next";
import {nationalityOptions} from "./NationalityDropdown";
import NotificationModal from "./NotificationModal"; // Ensure you import this

const generatePDF = async () => {
    const resumePreview = document.querySelector('.resume-preview');
    if (!resumePreview) {
        alert('Resume content not found!');
        return;
    }

    try {
        // Helper function to convert an image URL to Base64
        const toBase64 = async (url) => {
            return new Promise((resolve, reject) => {
                fetch(url, {mode: "cors"}) // Ensure CORS is enabled
                    .then(response => response.blob())
                    .then(blob => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.onerror = reject;
                        reader.readAsDataURL(blob);
                    })
                    .catch(reject);
            });
        };

        // Convert profile image to Base64 if it's from an external domain
        const profileImg = document.querySelector(".preview-photo");
        if (profileImg && profileImg.src.startsWith("http") && !profileImg.src.startsWith("data:image")) {
            try {
                profileImg.src = await toBase64(profileImg.src); // Convert to Base64
            } catch (error) {
                console.error("Failed to load profile image:", error);
            }
        }

        // Ensure all images are fully loaded
        const loadImages = () => {
            return new Promise((resolve) => {
                const images = resumePreview.querySelectorAll('img');
                let loadedImages = 0;

                if (images.length === 0) {
                    resolve();
                    return;
                }

                images.forEach((img) => {
                    if (img.complete) {
                        loadedImages++;
                        if (loadedImages === images.length) resolve();
                    } else {
                        img.onload = () => {
                            loadedImages++;
                            if (loadedImages === images.length) resolve();
                        };
                        img.onerror = () => {
                            loadedImages++;
                            if (loadedImages === images.length) resolve();
                        };
                    }
                });
            });
        };

        await loadImages(); // Ensure all images are loaded

        // Expand resume-preview to capture full content
        const originalHeight = resumePreview.style.height;
        resumePreview.style.height = "auto";

        const originalWidth = resumePreview.style.width;
        resumePreview.style.width = "1025px"; // force desktop media query

        const canvas = await html2canvas(resumePreview, {
            scale: 2,
            useCORS: true,
            allowTaint: false,
            logging: true,
            scrollY: -window.scrollY
        });

        resumePreview.style.height = originalHeight; // Reset height

        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        const imgWidth = canvas.width;
        const imgHeight = canvas.height;

        const scaleRatio = pdfWidth / imgWidth;
        const scaledHeight = imgHeight * scaleRatio;

        let yPosition = 0;
        let page = 0;

        while (yPosition < imgHeight) {
            if (page > 0) pdf.addPage();
            pdf.addImage(imgData, "PNG", 0, -yPosition * scaleRatio, pdfWidth, scaledHeight, undefined, "FAST");
            yPosition += pdfHeight / scaleRatio;
            page++;
        }

        const now = new Date();
        const timestamp = `${now.getFullYear()}${(now.getMonth() + 1).toString().padStart(2, '0')}${now.getDate().toString().padStart(2, '0')}${now.getHours().toString().padStart(2, '0')}${now.getMinutes().toString().padStart(2, '0')}${now.getSeconds().toString().padStart(2, '0')}`;

        pdf.save(`CVBlok_CV_${timestamp}.pdf`);
        resumePreview.style.width = originalWidth;
    } catch (error) {
        console.error("Error generating PDF:", error);
        alert("Failed to generate PDF.");
    }
};

const ResumeEditor = () => {
    const [sections, setSections] = useState({
        personal: false,
        berufserfahrung: false,
        bildung: false,
        referenzen: false,
        weiterbildungskurse: false,
        sprachen: false
    });
    const {t, i18n} = useTranslation();

    const toggleSection = (section) => {
        setSections((prev) => ({...prev, [section]: !prev[section]}));
    };

    const [resumeData, setResumeData] = useState({
        personalData: {
            firstName: '',
            lastName: '',
            gender: '',
            email: '',
            phone: '',
            phoneCountryCode: '',
            address: '',
            postalCode: '',
            city: '',
            beruf: '',
            birthdate: '',
            birthplace: '',
            driverslicense: '',
            nationality: [],
            maritalstatus: '',
            languageFields: [],
            educationFields: [],
            referencesFields: [],
            weiterbildungskurseFields: [],
            berufserfahrungFields: [],
            anerkennung: [],
        },
        photo: null,
        template: 'template1'
    });
    const {id} = useParams();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [previewPhoto, setPreviewPhoto] = useState(null); // Separate state for the preview image
    const [anerkennungFile, setAnerkennungFile] = useState(null); // Separate state for the preview image

    useEffect(() => {
        const fetchCV = async () => {
            if (id) {
                try {
                    const {data} = await axios.get(`https://backend.cvblok.com/cvs/${id}`, {
                        headers: {Authorization: `Bearer ${token}`},
                    });

                    // Check if data and personalData exist
                    if (data && data.personalData) {
                        setResumeData({
                            personalData: data.personalData,
                            photo: data.personalData.photo || null,
                            template: data.personalData.template || 'template1', // If template exists in response, set it, else default to 'template1'
                        });
                    }
                } catch (error) {
                    console.error('Error fetching CV:', error);
                } finally {
                    setLoading(false);
                }
            } else {
                // If no CV ID is provided, set default data
                setResumeData({
                    personalData: {
                        firstName: '',
                        lastName: '',
                        gender: '',
                        email: '',
                        phone: '',
                        phoneCountryCode: '',
                        address: '',
                        postalCode: '',
                        city: '',
                        beruf: '',
                        birthdate: '',
                        birthplace: '',
                        driverslicense: '',
                        nationality: [],
                        maritalstatus: '',
                        languageFields: [],
                        educationFields: [],
                        referencesFields: [],
                        weiterbildungskurseFields: [],
                        berufserfahrungFields: [],
                        anerkennung: [],
                    },
                    photo: null,
                    template: 'template1',
                });
                setLoading(false);
            }
        };
        fetchCV();
    }, [id, token]);

    const countryCodes = [
        {code: "+49", country: "🇩🇪 Deutschland"},
        {code: "+1", country: "🇺🇸 USA / 🇨🇦 Kanada"},
        {code: "+33", country: "🇫🇷 Frankreich"},
        {code: "+34", country: "🇪🇸 Spanien"},
        {code: "+39", country: "🇮🇹 Italien"},
        {code: "+90", country: "🇹🇷 Türkei"},
        {code: "+44", country: "🇬🇧 Vereinigtes Königreich"},
        {code: "+31", country: "🇳🇱 Niederlande"},
        {code: "+351", country: "🇵🇹 Portugal"},
        {code: "+7", country: "🇷🇺 Russland"},
        {code: "+971", country: "🇦🇪 VAE"},
        {code: "+81", country: "🇯🇵 Japan"},
        {code: "+86", country: "🇨🇳 China"},
        {code: "+966", country: "🇸🇦 Saudi-Arabien"}
    ];

    const handleChange = (e) => {
        const {name, value} = e.target;
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, [name]: value},
            photo: prevData.photo
        }));
        setUnsavedChanges(true);
    };

    const driverLicenseOptions = [
        "A (Motorrad)", "A1 (Leichtkraftrad)", "A2 (Mittelschwere Motorräder)",
        "AM (Moped)", "B (Auto)", "BE (Auto mit Anhänger)", "C (LKW über 3,5t)",
        "C1 (LKW bis 7,5t)", "C1E (LKW bis 7,5t mit Anhänger)", "CE (LKW mit Anhänger)",
        "D (Bus)", "D1 (Kleinbus bis 16 Personen)", "D1E (Kleinbus mit Anhänger)", "DE (Bus mit Anhänger)"
    ];

    const handleLicenseChange = (e) => {
        const {value, checked} = e.target;
        setResumeData((prevData) => {
            const currentLicenses = Array.isArray(prevData.personalData.driverslicense)
                ? prevData.personalData.driverslicense
                : [];

            const updatedLicenses = checked
                ? [...currentLicenses, value] // Hinzufügen
                : currentLicenses.filter((license) => license !== value); // Entfernen

            return {
                ...prevData,
                personalData: {...prevData.personalData, driverslicense: updatedLicenses},
            };
        });

        setUnsavedChanges(true);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0]; // Get the selected file
        if (file) {
            const photoUrl = URL.createObjectURL(file); // Generate Blob URL for preview
            setPreviewPhoto(photoUrl); // Update the preview state
            setResumeData((prevData) => ({
                ...prevData,
                photo: file, // Keep the file object for uploading
            }));
            setUnsavedChanges(true); // Mark changes as unsaved
        }
    };

    const handleAnerkennungFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setAnerkennungFile(file); // Store file in state for uploading
            setResumeData((prevData) => ({
                ...prevData,
                personalData: {
                    ...prevData.personalData,
                    anerkennung: {
                        ...prevData.personalData.anerkennung,
                        document: file // Attach file to Anerkennung data
                    }
                }
            }));
            setUnsavedChanges(true);
        }
    };

    const handleAnerkennungChange = (field, value) => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                anerkennung: {
                    ...prevData.personalData.anerkennung,
                    [field]: value
                }
            }
        }));
    };

    const handleLanguageChange = (index, field, value) => {
        const updatedLanguages = [...resumeData.personalData.languageFields];
        updatedLanguages[index] = {...updatedLanguages[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, languageFields: updatedLanguages}
        }));
        setUnsavedChanges(true);
    };

    const addLanguageField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                languageFields: [
                    ...prevData.personalData.languageFields ?? [],
                    {language: '', level: '', certificate: null}
                ]
            }
        }));
        setUnsavedChanges(true);
    };

    const removeLanguageField = (index) => {
        const updatedLanguages = resumeData.personalData.languageFields.filter((_, i) => i !== index);
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, languageFields: updatedLanguages}
        }));
        setUnsavedChanges(true);
    };

    const degreesMap = {
        de: [
            "Grundschule",
            "Realschule",
            "Berufsfachschule",
            "Fachoberschule/Abitur",
            "Vordiplom",
            "Bachelor",
            "Master",
            "Doktorgrad"
        ],
        en: [
            "Primary School",
            "Secondary School",
            "Vocational School",
            "High School Diploma",
            "Pre-Diploma",
            "Bachelor",
            "Master",
            "Doctorate"
        ],
        tr: [
            "İlkokul",
            "Ortaokul",
            "Meslek Lisesi",
            "Lise Diploması",
            "Önlisans",
            "Lisans",
            "Yüksek Lisans",
            "Doktora"
        ]
    };
    const degrees = degreesMap[i18n.language] || degreesMap['de']; // Fallback to German

    const languagesMap = {
        de: [
            "Deutsch", "Englisch", "Französisch", "Spanisch", "Italienisch",
            "Türkisch", "Niederländisch", "Portugiesisch", "Russisch", "Arabisch",
            "Chinesisch", "Japanisch"
        ],
        en: [
            "German", "English", "French", "Spanish", "Italian",
            "Turkish", "Dutch", "Portuguese", "Russian", "Arabic",
            "Chinese", "Japanese"
        ],
        tr: [
            "Almanca", "İngilizce", "Fransızca", "İspanyolca", "İtalyanca",
            "Türkçe", "Flemenkçe", "Portekizce", "Rusça", "Arapça",
            "Çince", "Japonca"
        ]
    };
    const languages = languagesMap[i18n.language] || languagesMap['de']; // Fallback to German


    const handleEducationFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.educationFields];
        updatedFields[index] = {...updatedFields[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, educationFields: updatedFields},
        }));
    };

    const addEducationField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                educationFields: [
                    ...prevData.personalData.educationFields ?? [],
                    {
                        degree: '',
                        industry: '',
                        fieldOfStudy: '',
                        institution: '',
                        startMonth: '',
                        startYear: '',
                        endMonth: '',
                        endYear: '',
                        isCurrent: false,
                    },
                ],
            },
        }));
    };

    const removeEducationField = (index) => {
        const updatedFields = resumeData.personalData.educationFields.filter((_, i) => i !== index);
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, educationFields: updatedFields},
        }));
    };

    const handleReferenceFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.referencesFields];
        updatedFields[index] = {...updatedFields[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, referencesFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const addReferenceField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                referencesFields: [
                    ...prevData.personalData.referencesFields ?? [],
                    {
                        name: '',
                        organization: '',
                        location: '',
                        phone: '',
                        email: '',
                    },
                ],
            },
        }));
        setUnsavedChanges(true);
    };

    const removeReferenceField = (index) => {
        const updatedFields = resumeData.personalData.referencesFields.filter(
            (_, i) => i !== index
        );
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, referencesFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const [notification, setNotification] = useState({message: '', isOpen: false});

    // Show Notification
    const showNotification = (messageKey) => {
        setNotification({message: t(messageKey), isOpen: true});
    };

    // Close Notification
    const closeNotification = () => {
        setNotification({message: '', isOpen: false});
    };

    const handleWeiterbildungskurseFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.weiterbildungskurseFields];
        updatedFields[index] = {...updatedFields[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                weiterbildungskurseFields: updatedFields,
            },
        }));
        setUnsavedChanges(true);
    };

    const addWeiterbildungskurseField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                weiterbildungskurseFields: [
                    ...prevData.personalData.weiterbildungskurseFields ?? [],
                    {
                        course: '',
                        startMonth: '',
                        startYear: '',
                        endMonth: '',
                        endYear: '',
                        ongoing: false,
                        description: '',
                    },
                ],
            },
        }));
        setUnsavedChanges(true);
    };

    const removeWeiterbildungskurseField = (index) => {
        const updatedFields = resumeData.personalData.weiterbildungskurseFields.filter(
            (_, i) => i !== index
        );
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                weiterbildungskurseFields: updatedFields,
            },
        }));
        setUnsavedChanges(true);
    };

    const handleBerufserfahrungFieldChange = (index, field, value) => {
        const updatedFields = [...resumeData.personalData.berufserfahrungFields];
        updatedFields[index] = {...updatedFields[index], [field]: value};
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, berufserfahrungFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const addBerufserfahrungField = () => {
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {
                ...prevData.personalData,
                berufserfahrungFields: [
                    ...prevData.personalData.berufserfahrungFields ?? [],
                    {
                        position: '',
                        employer: '',
                        location: '',
                        startMonth: '',
                        startYear: '',
                        endMonth: '',
                        endYear: '',
                        isCurrent: false,
                        description: '',
                    },
                ],
            },
        }));
        setUnsavedChanges(true);
    };

    const removeBerufserfahrungField = (index) => {
        const updatedFields = resumeData.personalData.berufserfahrungFields.filter(
            (_, i) => i !== index
        );
        setResumeData((prevData) => ({
            ...prevData,
            personalData: {...prevData.personalData, berufserfahrungFields: updatedFields},
        }));
        setUnsavedChanges(true);
    };

    const handleSubmit = async () => {
        const token = localStorage.getItem('token'); // Get token from localStorage
        if (!token) {
            alert("You must be logged in to create or update a CV");
            return;
        }

        const formData = new FormData();
        formData.append('personal_data', JSON.stringify({
            firstName: resumeData.personalData.firstName,
            lastName: resumeData.personalData.lastName,
            gender: resumeData.personalData.gender,
            email: resumeData.personalData.email,
            phoneCountryCode: resumeData.personalData.phoneCountryCode,
            phone: resumeData.personalData.phone,
            address: resumeData.personalData.address,
            postalCode: resumeData.personalData.postalCode,
            city: resumeData.personalData.city,
            beruf: resumeData.personalData.beruf,
            birthdate: resumeData.personalData.birthdate,
            birthplace: resumeData.personalData.birthplace,
            driverslicense: resumeData.personalData.driverslicense,
            nationality: resumeData.personalData.nationality,
            maritalstatus: resumeData.personalData.maritalstatus,
            languageFields: resumeData.personalData.languageFields.map(lang => ({
                language: lang.language,
                level: lang.level
            })),
            educationFields: resumeData.personalData.educationFields,
            referencesFields: resumeData.personalData.referencesFields,
            weiterbildungskurseFields: resumeData.personalData.weiterbildungskurseFields,
            berufserfahrungFields: resumeData.personalData.berufserfahrungFields,
            anerkennung: resumeData.personalData.anerkennung,
        }));

        resumeData.personalData.languageFields.forEach((lang, index) => {
            if (lang.certificate) {
                formData.append(`certificate_${index}`, lang.certificate);
            }
        });

        if (resumeData.personalData.anerkennung?.document instanceof File) {
            formData.append('anerkennung_document', resumeData.personalData.anerkennung.document);
        }

        if (resumeData.photo) {
            formData.append('photo', resumeData.photo);
        }

        const decodedToken = jwtDecode(token);
        const userId = decodedToken.id;
        // Creating new CV
        formData.append('user_id', userId);

        try {
            if (id) {
                formData.append('cv_id', id);
                const response = await axios.post(`https://backend.cvblok.com/cvs/update`, formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setUnsavedChanges(false);
                showNotification(t('updated_successfully'));
                console.log('CV updated successfully:', response.data);
            } else {
                const response = await axios.post('https://backend.cvblok.com/cvs/create', formData, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                });

                console.log('CV created successfully:', response.data);
                navigate(`/editor/${response.data.id}`);
            }
        } catch (error) {
            console.error('Error saving CV:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(error.response?.data?.message || 'An error occurred');
            }
        }
    };

    const [base64Photo, setBase64Photo] = useState(null);

    useEffect(() => {
        const fetchBase64Image = async () => {
            try {
                console.log("Fetching base64 image:", resumeData.photo); // DEBUG
                const filename = resumeData.photo.replace(/^uploads\/photos\//, '');
                const response = await fetch(`https://backend.cvblok.com/api/image/base64/${filename}`);

                if (!response.ok) throw new Error("Image fetch failed");

                const data = await response.json(); // Parse JSON
                setBase64Photo(data.base64); // Set Base64 directly
            } catch (error) {
                console.error("Error fetching base64 image:", error);
            }
        };

        if (resumeData.photo && typeof resumeData.photo === "string") {
            fetchBase64Image();
        }
    }, [resumeData.photo]);


    const handleTemplateChange = (e) => {
        setResumeData((prevData) => ({
            ...prevData,
            template: e.target.value
        }));
    };

    const getGenderIcon = (gender) => {
        switch (gender) {
            case t('male'):
                return faMars;
            case t('female'):
                return faVenus;
            default:
                return '';
        }
    };

    const handleBackToDashboard = () => {
        if (unsavedChanges) {
            const confirmLeave = window.confirm(t('youHaveUnsavedChanges'));
            if (!confirmLeave) return;
        }
        navigate('/dashboard');
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <div className="top-bar">
                <h1>CVBlok Editor</h1>
                <div>
                    <button className="top-bar-button" onClick={generatePDF}>
                        <FontAwesomeIcon icon={faDownload} style={{marginRight: 5}}/>
                        {t('download_cv')}
                    </button>

                    <button onClick={handleBackToDashboard} className="top-bar-button"><FontAwesomeIcon
                        icon={faArrowLeft} style={{marginRight: 5}}/>{t('back_to_dashboard')}
                    </button>
                    <button onClick={handleSubmit} className="top-bar-button"><FontAwesomeIcon icon={faSave}
                                                                                               style={{marginRight: 5}}/>{t('save')}
                    </button>
                </div>
            </div>
            <div className="editor-layout">
                <div className="editor-form">
                    <h2>{id ? t('edit_resume') : t('create_new_resume')}</h2>

                    <h3 onClick={() => toggleSection('personal')} style={{cursor: 'pointer'}}>
                        {t('personal_info')}
                        <FontAwesomeIcon icon={sections.personal ? faChevronDown : faChevronRight}
                                         style={{marginRight: 8, marginLeft: 'auto'}}/>
                    </h3>

                    {sections.personal && (
                        <div>
                            <div>
                                <label>{t('upload_photo')}</label>
                                <input type="file" onChange={handleFileChange}/>
                            </div>

                            {/* Standard Fields */}
                            <input type="text" name="firstName" value={resumeData.personalData.firstName}
                                   onChange={handleChange} placeholder={t('first_name')}/>
                            <input type="text" name="lastName" value={resumeData.personalData.lastName}
                                   onChange={handleChange}
                                   placeholder={t('last_name')}/>
                            <label htmlFor="gender">{t('gender')}</label>
                            <select id="gender" name="gender" value={resumeData.personalData.gender || ''}
                                    onChange={handleChange}>
                                <option value="">{t('please_select')}</option>
                                <option value={t('male')}>{t('male')}</option>
                                <option value={t('female')}>{t('female')}</option>
                                <option value={t('diverse')}>{t('diverse')}</option>
                                <option value={t('no_specify')}>{t('no_specify')}</option>
                            </select>
                            <input type="text" name="beruf" value={resumeData.personalData.beruf}
                                   onChange={handleChange}
                                   placeholder={t('job_position')}/>
                            <input type="email" name="email" value={resumeData.personalData.email}
                                   onChange={handleChange}
                                   placeholder={t('email_address')}/>
                            <div className="phone-input-group">
                                <div className="phone-wrapper">
                                    <select
                                        name="phoneCountryCode"
                                        value={resumeData.personalData.phoneCountryCode || "+49"}
                                        onChange={handleChange}
                                        className="phone-country-code"
                                    >
                                        {countryCodes.map(({code, country}) => (
                                            <option key={code} value={code}>
                                                {country} ({code})
                                            </option>
                                        ))}
                                    </select>
                                    <input
                                        className="phone-number"
                                        type="tel"
                                        name="phone"
                                        value={resumeData.personalData.phone}
                                        onChange={handleChange}
                                        placeholder={t('phone_number')}
                                    />
                                </div>
                            </div>
                            <input type="text" name="address" value={resumeData.personalData.address}
                                   onChange={handleChange}
                                   placeholder={t('address')}/>
                            <input type="text" name="postalCode" value={resumeData.personalData.postalCode}
                                   onChange={handleChange} placeholder={t('postal_code')}/>
                            <input type="text" name="city" value={resumeData.personalData.city} onChange={handleChange}
                                   placeholder={t('city')}/>
                            <div>
                                <input
                                    type="date"
                                    name="birthdate"
                                    value={resumeData.personalData.birthdate}
                                    onChange={handleChange}
                                    placeholder={t('birth_date')}
                                />
                                <input
                                    type="text"
                                    name="birthplace"
                                    value={resumeData.personalData.birthplace}
                                    onChange={handleChange}
                                    placeholder={t('birth_place')}
                                />

                                {/* Dropdown for Marital Status */}
                                <label htmlFor="maritalstatus">{t('marital_status')}</label>
                                <select
                                    id="maritalstatus"
                                    name="maritalstatus"
                                    value={resumeData.personalData.maritalstatus}
                                    onChange={handleChange}
                                >
                                    <option value="">{t('please_select')}</option>
                                    <option value="ledig">Ledig</option>
                                    <option value="verheiratet">Verheiratet</option>
                                    <option value="geschieden">Geschieden</option>
                                    <option value="verwitwet">Verwitwet</option>
                                    <option value="eingetragene Partnerschaft">Eingetragene Partnerschaft</option>
                                </select>
                                <div className="anerkennung-fields">
                                    <label>{t('recognition_applied')}</label>
                                    <select
                                        name="anerkennungApplied"
                                        value={resumeData.personalData.anerkennung?.applied || ''}
                                        onChange={(e) => handleAnerkennungChange('applied', e.target.value)}
                                    >
                                        <option value="">{t('please_select')}</option>
                                        <option value="yes">{t('yes')}</option>
                                        <option value="no">{t('no')}</option>
                                    </select>

                                    {/* If 'yes' is selected, show additional fields */}
                                    {resumeData.personalData.anerkennung?.applied === 'yes' && (
                                        <>
                                            <input
                                                type="text"
                                                name="anerkennungPlace"
                                                value={resumeData.personalData.anerkennung?.place || ''}
                                                onChange={(e) => handleAnerkennungChange('place', e.target.value)}
                                                placeholder={t('recognition_place')}
                                            />
                                            <input
                                                type="text"
                                                name="anerkennungAs"
                                                value={resumeData.personalData.anerkennung?.as || ''}
                                                onChange={(e) => handleAnerkennungChange('as', e.target.value)}
                                                placeholder={t('recognition_as')}
                                            />
                                            <input
                                                type="date"
                                                name="anerkennungDate"
                                                value={resumeData.personalData.anerkennung?.date || ''}
                                                onChange={(e) => handleAnerkennungChange('date', e.target.value)}
                                                placeholder={t('recognition_date')}
                                            />

                                            <label>{t('recognition_result')}</label>
                                            <select
                                                name="anerkennungResult"
                                                value={resumeData.personalData.anerkennung?.result || ''}
                                                onChange={(e) => handleAnerkennungChange('result', e.target.value)}
                                            >
                                                <option value="">{t('please_select')}</option>
                                                <option value="full">{t('recognition_full')}</option>
                                                <option value="partial">{t('recognition_partial')}</option>
                                                <option value="in_progress">{t('recognition_in_progress')}</option>
                                                <option value="none">{t('recognition_none')}</option>
                                            </select>

                                            <label>{t('recognition_document')}</label>
                                            <input type="file" onChange={handleAnerkennungFileChange}/>

                                            {/* Show download link if a document is uploaded */}
                                            {resumeData.personalData.anerkennung?.document && (
                                                <a href={`https://backend.cvblok.com/${resumeData.personalData.anerkennung.document}`}
                                                   target="_blank" rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faDownload} className="icon-download"
                                                                     title={t('download_certificate')}/> {t('download_certificate')}
                                                </a>
                                            )}
                                        </>
                                    )}
                                </div>

                                <label>{t('driver_license')}</label>
                                <DriversLicenseDropdown
                                    selectedDriversLicenses={resumeData.personalData.driverslicense}
                                    setResumeData={setResumeData}
                                    setUnsavedChanges={setUnsavedChanges}
                                />

                                <label>{t('nationality')}</label>
                                <NationalityDropdown
                                    selectedNationalities={resumeData.personalData.nationality}
                                    setResumeData={setResumeData}
                                    setUnsavedChanges={setUnsavedChanges}
                                />
                            </div>
                        </div>
                    )}

                    <h3 onClick={() => toggleSection('sprachen')} style={{cursor: 'pointer'}}>
                        {t('languages')}
                        <FontAwesomeIcon icon={sections.sprachen ? faChevronDown : faChevronRight}
                                         style={{marginRight: 8, marginLeft: 'auto'}}/>
                    </h3>

                    {sections.sprachen && (
                        <div className="language-fields">
                            {resumeData.personalData.languageFields.map((field, index) => (
                                <div key={index} className="language-field">
                                    <div className="language-input-group">
                                        {/* Sprach-Auswahl als Dropdown */}
                                        <div className="language-dropdown">
                                            <label>{t('language')}</label>
                                            <select
                                                value={field.language}
                                                onChange={(e) => handleLanguageChange(index, 'language', e.target.value)}
                                            >
                                                <option value="">{t('select_language')}</option>
                                                {languages.map((lang, i) => (
                                                    <option key={i} value={lang}>{lang}</option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="language-dropdown">
                                            <label>{t('language_level')}</label>
                                            <select
                                                value={field.level}
                                                onChange={(e) => handleLanguageChange(index, 'level', e.target.value)}
                                            >
                                                <option value="">{t('select_language_level')}</option>
                                                <option value="A1">{t('language_level_a1')}</option>
                                                <option value="A2">{t('language_level_a2')}</option>
                                                <option value="B1">{t('language_level_b1')}</option>
                                                <option value="B2">{t('language_level_b2')}</option>
                                                <option value="C1">{t('language_level_c1')}</option>
                                                <option value="C2">{t('language_level_c2')}</option>
                                                <option value="Muttersprache">{t('native_language')}</option>
                                            </select>
                                        </div>
                                    </div>

                                    {/* Zertifikat Upload & Download Link */}
                                    <div className="certificate-upload">
                                        <label>{t('certificate')}
                                        </label>
                                        <div className="certificate-container">
                                            <input
                                                type="file"
                                                accept=".pdf, .jpg, .png"
                                                onChange={(e) => handleLanguageChange(index, 'certificate', e.target.files[0])}
                                            />

                                            {/* Show Download Icon if Certificate Exists */}
                                            {field.certificate && typeof field.certificate === "string" && (
                                                <a href={`https://backend.cvblok.com/${field.certificate}`}
                                                   target="_blank"
                                                   rel="noopener noreferrer">
                                                    <FontAwesomeIcon icon={faDownload} className="icon-download"
                                                                     title={t('download_certificate')}/>
                                                </a>
                                            )}
                                        </div>
                                    </div>

                                    {/* Remove Button */}
                                    <button className="remove-btn" onClick={() => removeLanguageField(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} className="icon-trash"/>
                                    </button>
                                </div>
                            ))}
                            <button onClick={addLanguageField}>{t('add_language')}</button>
                        </div>
                    )}

                    <h3 onClick={() => toggleSection('berufserfahrung')} style={{cursor: 'pointer'}}>
                        {t('work_experience')}
                        <FontAwesomeIcon icon={sections.berufserfahrung ? faChevronDown : faChevronRight}
                                         style={{marginRight: 8, marginLeft: 'auto'}}/>
                    </h3>
                    {sections.berufserfahrung && (
                        <div className="berufserfahrung-fields">
                            {resumeData.personalData.berufserfahrungFields && resumeData.personalData.berufserfahrungFields.map((field, index) => (
                                <div key={index} className="berufserfahrung-field">
                                    <input
                                        type="text"
                                        placeholder={t('position')}
                                        value={field.position}
                                        onChange={(e) =>
                                            handleBerufserfahrungFieldChange(index, 'position', e.target.value)
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder={t('employer')}
                                        value={field.employer}
                                        onChange={(e) =>
                                            handleBerufserfahrungFieldChange(index, 'employer', e.target.value)
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder={t('city')}
                                        value={field.location}
                                        onChange={(e) =>
                                            handleBerufserfahrungFieldChange(index, 'location', e.target.value)
                                        }
                                    />
                                    <div className="date-group">
                                        <select
                                            value={field.startMonth}
                                            onChange={(e) =>
                                                handleBerufserfahrungFieldChange(index, 'startMonth', e.target.value)
                                            }
                                        >
                                            <option value="">{t('month')}</option>
                                            {Array.from({length: 12}, (_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {new Date(0, i).toLocaleString(i18n.language, {month: 'long'})}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            placeholder={t('year')}
                                            value={field.startYear}
                                            onChange={(e) =>
                                                handleBerufserfahrungFieldChange(index, 'startYear', e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="date-group">
                                        <select
                                            value={field.endMonth}
                                            onChange={(e) =>
                                                handleBerufserfahrungFieldChange(index, 'endMonth', e.target.value)
                                            }
                                            disabled={field.isCurrent}
                                        >
                                            <option value="">{t('month')}</option>
                                            {Array.from({length: 12}, (_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {new Date(0, i).toLocaleString(i18n.language, {month: 'long'})}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            placeholder={t('year')}
                                            value={field.endYear}
                                            onChange={(e) =>
                                                handleBerufserfahrungFieldChange(index, 'endYear', e.target.value)
                                            }
                                            disabled={field.isCurrent}
                                        />
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={field.isCurrent}
                                                onChange={(e) =>
                                                    handleBerufserfahrungFieldChange(
                                                        index,
                                                        'isCurrent',
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                            {t('ongoing')}
                                        </label>
                                    </div>
                                    <div className="berufserfahrung-buttons">
                                        <button onClick={() => removeBerufserfahrungField(index)}>
                                            <FontAwesomeIcon icon={faTrashAlt} className="icon-sidebar icon-white"/>
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button onClick={addBerufserfahrungField}>{t('add_experience')}</button>
                        </div>
                    )}

                    <h3 onClick={() => toggleSection('bildung')} style={{cursor: 'pointer'}}>
                        {t('education')}
                        <FontAwesomeIcon icon={sections.bildung ? faChevronDown : faChevronRight}
                                         style={{marginRight: 8, marginLeft: 'auto'}}/>
                    </h3>
                    {sections.bildung && (
                        <div className="education-fields">
                            {resumeData.personalData.educationFields.map((field, index) => (
                                <div key={index} className="education-field">
                                    <select
                                        value={field.degree}
                                        onChange={(e) => handleEducationFieldChange(index, 'degree', e.target.value)}
                                    >
                                        <option value="">{t('select_degree')}</option>
                                        {degrees.map((deg) => (
                                            <option key={deg} value={deg}>{deg}</option>
                                        ))}
                                    </select>
                                    <input
                                        type="text"
                                        placeholder={t('industry')}
                                        value={field.industry}
                                        onChange={(e) => handleEducationFieldChange(index, 'industry', e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder={t('field_of_study')}
                                        value={field.fieldOfStudy}
                                        onChange={(e) => handleEducationFieldChange(index, 'fieldOfStudy', e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder={t('institution')}
                                        value={field.institution}
                                        onChange={(e) => handleEducationFieldChange(index, 'institution', e.target.value)}
                                    />
                                    <div className="date-group">
                                        <select
                                            value={field.startMonth}
                                            onChange={(e) => handleEducationFieldChange(index, 'startMonth', e.target.value)}
                                        >
                                            <option value="">{t('month')}</option>
                                            {Array.from({length: 12}, (_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {new Date(0, i).toLocaleString(i18n.language, {month: 'long'})}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            placeholder={t('year')}
                                            value={field.startYear}
                                            onChange={(e) => handleEducationFieldChange(index, 'startYear', e.target.value)}
                                        />
                                    </div>
                                    <div className="date-group">
                                        <select
                                            value={field.endMonth}
                                            onChange={(e) => handleEducationFieldChange(index, 'endMonth', e.target.value)}
                                            disabled={field.isCurrent}
                                        >
                                            <option value="">{t('month')}</option>
                                            {Array.from({length: 12}, (_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {new Date(0, i).toLocaleString(i18n.language, {month: 'long'})}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            placeholder={t('year')}
                                            value={field.endYear}
                                            onChange={(e) => handleEducationFieldChange(index, 'endYear', e.target.value)}
                                            disabled={field.isCurrent}
                                        />
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={field.isCurrent}
                                                onChange={(e) => handleEducationFieldChange(index, 'isCurrent', e.target.checked)}
                                            />
                                            {t('ongoing')}
                                        </label>
                                    </div>
                                    <button onClick={() => removeEducationField(index)}>
                                        <FontAwesomeIcon icon={faTrashAlt} className="icon-sidebar icon-white"/>
                                    </button>
                                </div>
                            ))}
                            <button onClick={addEducationField}>{t('add_education')}</button>
                        </div>
                    )}

                    <h3 onClick={() => toggleSection('referenzen')} style={{cursor: 'pointer'}}>
                        {t('references')}
                        <FontAwesomeIcon icon={sections.referenzen ? faChevronDown : faChevronRight}
                                         style={{marginRight: 8, marginLeft: 'auto'}}/>
                    </h3>

                    {sections.referenzen && (
                        <div className="references-fields">
                            {resumeData.personalData.referencesFields && resumeData.personalData.referencesFields.map((field, index) => (
                                <div key={index} className="reference-field">
                                    <input
                                        type="text"
                                        placeholder="Name"
                                        value={field.name}
                                        onChange={(e) =>
                                            handleReferenceFieldChange(index, 'name', e.target.value)
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder={t('organization')}
                                        value={field.organization}
                                        onChange={(e) =>
                                            handleReferenceFieldChange(index, 'organization', e.target.value)
                                        }
                                    />
                                    <input
                                        type="text"
                                        placeholder={t('city')}
                                        value={field.location}
                                        onChange={(e) =>
                                            handleReferenceFieldChange(index, 'location', e.target.value)
                                        }
                                    />
                                    <input
                                        type="tel"
                                        placeholder={t('phone_number')}
                                        value={field.phone}
                                        onChange={(e) =>
                                            handleReferenceFieldChange(index, 'phone', e.target.value)
                                        }
                                    />
                                    <input
                                        type="email"
                                        placeholder={t('email_address')}
                                        value={field.email}
                                        onChange={(e) =>
                                            handleReferenceFieldChange(index, 'email', e.target.value)
                                        }
                                    />
                                    <div className="reference-buttons">
                                        <button onClick={() => removeReferenceField(index)}><FontAwesomeIcon
                                            icon={faTrashAlt}
                                            className="icon-sidebar icon-white"/></button>
                                    </div>
                                </div>
                            ))}
                            <button onClick={addReferenceField}>{t('add_reference')}</button>
                        </div>
                    )}

                    <h3 onClick={() => toggleSection('weiterbildungskurse')} style={{cursor: 'pointer'}}>
                        {t('additional_training')}
                        <FontAwesomeIcon icon={sections.weiterbildungskurse ? faChevronDown : faChevronRight}
                                         style={{marginRight: 8, marginLeft: 'auto'}}/>
                    </h3>

                    {sections.weiterbildungskurse && (
                        <div className="weiterbildungskurse-fields">
                            {resumeData.personalData.weiterbildungskurseFields && resumeData.personalData.weiterbildungskurseFields.map((field, index) => (
                                <div key={index} className="weiterbildungskurs-field">
                                    <input
                                        type="text"
                                        placeholder="Kurs"
                                        value={field.course}
                                        onChange={(e) =>
                                            handleWeiterbildungskurseFieldChange(index, 'course', e.target.value)
                                        }
                                    />
                                    <div className="date-group">
                                        <select
                                            value={field.startMonth}
                                            onChange={(e) =>
                                                handleWeiterbildungskurseFieldChange(index, 'startMonth', e.target.value)
                                            }
                                        >
                                            <option value="">{t('month')}</option>
                                            {Array.from({length: 12}, (_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {new Date(0, i).toLocaleString(i18n.language, {month: 'long'})}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            placeholder={t('year')}
                                            value={field.startYear}
                                            onChange={(e) =>
                                                handleWeiterbildungskurseFieldChange(index, 'startYear', e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="date-group">
                                        <select
                                            value={field.endMonth}
                                            onChange={(e) =>
                                                handleWeiterbildungskurseFieldChange(index, 'endMonth', e.target.value)
                                            }
                                            disabled={field.isCurrent}
                                        >
                                            <option value="">{t('month')}</option>
                                            {Array.from({length: 12}, (_, i) => (
                                                <option key={i} value={i + 1}>
                                                    {new Date(0, i).toLocaleString(i18n.language, {month: 'long'})}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="number"
                                            placeholder={t('year')}
                                            value={field.endYear}
                                            onChange={(e) =>
                                                handleWeiterbildungskurseFieldChange(index, 'endYear', e.target.value)
                                            }
                                            disabled={field.isCurrent}
                                        />
                                        <label>
                                            <input
                                                type="checkbox"
                                                checked={field.isCurrent}
                                                onChange={(e) =>
                                                    handleWeiterbildungskurseFieldChange(
                                                        index,
                                                        'isCurrent',
                                                        e.target.checked
                                                    )
                                                }
                                            />
                                            {t('ongoing')}
                                        </label>
                                    </div>
                                    <div className="weiterbildungskurse-buttons">
                                        <button onClick={() => removeWeiterbildungskurseField(index)}>
                                            <FontAwesomeIcon icon={faTrashAlt} className="icon-sidebar icon-white"/>
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <button onClick={addWeiterbildungskurseField}>+ Kurs hinzufügen</button>
                        </div>
                    )}
                </div>

                <div className="resume-preview">
                    <div className="preview-sidebar">
                        <div className="personal-data">
                            <img
                                src={base64Photo || previewPhoto || logo}
                                alt="Profile"
                                className="preview-photo"
                            />

                            <div className="headSidebar">
                                <h2>{resumeData.personalData.firstName} {resumeData.personalData.lastName}</h2>
                                <p>{resumeData.personalData.beruf || ''}</p>
                            </div>

                            <hr/>
                            <p>
                                {resumeData.personalData.gender && (
                                    <>
                                        <strong>{t('gender')}:</strong> {resumeData.personalData.gender}
                                        <FontAwesomeIcon
                                            icon={getGenderIcon(resumeData.personalData.gender)}
                                            style={{marginLeft: "5px", color: "#555"}}
                                            title={resumeData.personalData.gender}
                                        />
                                    </>
                                )}
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCalendar} className="icon-sidebar"/>
                                {resumeData.personalData.birthdate ? new Intl.DateTimeFormat(i18n.language, {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric'
                                }).format(new Date(resumeData.personalData.birthdate)) : t('not_specified')}</p>
                            <p><FontAwesomeIcon icon={faMapMarker}
                                                className="icon-sidebar"/> {resumeData.personalData.birthplace || t('not_specified')}
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faPhone} className="icon-sidebar"/>
                                {resumeData.personalData.phoneCountryCode || ''} {resumeData.personalData.phone || t('not_specified')}
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faMapLocationDot}
                                                 className="icon-sidebar"/> {resumeData.personalData.address}, {resumeData.personalData.postalCode} {resumeData.personalData.city}
                            </p>
                            <p><FontAwesomeIcon icon={faEnvelope}
                                                className="icon-sidebar"/> {resumeData.personalData.email || t('not_specified')}
                            </p>
                            <hr/>
                            <p>
                                <strong>{t("nationality")}: </strong>
                                {resumeData.personalData.nationality.length > 0
                                    ? resumeData.personalData.nationality
                                        .map((code) => nationalityOptions[i18n.language][code] || code)
                                        .join(", ")
                                    : t("not_specified")}
                            </p>
                            <p>
                                <strong>{t('marital_status')}:</strong> {resumeData.personalData.maritalstatus || t('not_specified')}
                            </p>
                            <p>
                                <strong>{t('driver_license')}: </strong>
                                {resumeData.personalData.driverslicense && resumeData.personalData.driverslicense.length > 0
                                    ? resumeData.personalData.driverslicense.join(", ") // Komma-separierte Ausgabe
                                    : "Nicht vorhanden"}
                            </p>
                            <hr/>
                            {resumeData.personalData.languageFields && (
                                <div>
                                    <h3>{t('languages')}</h3>
                                    <ul style={{textAlign: 'left', listStyle: 'none'}}>
                                        {resumeData.personalData.languageFields.map((field, index) => (
                                            <li key={index} style={{fontSize: '12px'}}>
                                                <strong>{field.language}</strong> - {field.level}
                                                {field.certificate && (
                                                    <span> ✅ </span>
                                                )}
                                            </li>
                                        ))}
                                    </ul>
                                    <span style={{
                                        padding: 0,
                                        margin: 0,
                                        fontSize: '10px'
                                    }}> ✅ = Zertifikat vorhanden</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="preview-content">
                        {resumeData.personalData.berufserfahrungFields && resumeData.personalData.berufserfahrungFields.length > 0 && (
                            <div>
                                <h3>{t('work_experience')}</h3>
                                <ul>
                                    {resumeData.personalData.berufserfahrungFields && resumeData.personalData.berufserfahrungFields.map((field, index) => (
                                        <li key={index} style={{marginBottom: '10px'}}>
                                            <strong>{field.position}</strong> bei {field.employer}, {field.location}
                                            <br/>
                                            {field.startMonth && field.startYear
                                                ? `${new Date(0, field.startMonth - 1).toLocaleString(i18n.language, {
                                                    month: 'long',
                                                })} ${field.startYear}`
                                                : ''}

                                            {field.isCurrent
                                                ? ` - ${t('ongoing')}`
                                                : field.endMonth && field.endYear
                                                    ? ` - ${new Date(0, field.endMonth - 1).toLocaleString(i18n.language, {
                                                        month: 'long',
                                                    })} ${field.endYear}`
                                                    : ''}
                                            <br/>
                                            <em>{field.description}</em>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {resumeData.personalData.educationFields.length > 0 && (
                            <div>
                                <h3>{t('education')}</h3>
                                <ul>
                                    {resumeData.personalData.educationFields.map((field, index) => (
                                        <li key={index} style={{marginBottom: '10px'}}>
                                            <strong>{field.degree}</strong> - {field.industry}, {field.fieldOfStudy}
                                            <br/>
                                            <em>{field.institution}</em>
                                            <br/>
                                            {field.startMonth && field.startYear
                                                ? `${new Date(0, field.startMonth - 1).toLocaleString(i18n.language, {month: 'long'})} ${field.startYear}`
                                                : ''}
                                            {field.isCurrent
                                                ? ` - ${t('ongoing')}`
                                                : field.endMonth && field.endYear
                                                    ? ` - ${new Date(0, field.endMonth - 1).toLocaleString(i18n.language, {month: 'long'})} ${field.endYear}`
                                                    : ''}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}

                        {resumeData.personalData.referencesFields && resumeData.personalData.referencesFields.length > 0 && (
                            <div>
                                <h3>{t('references')}</h3>
                                <ul>
                                    {resumeData.personalData.referencesFields && resumeData.personalData.referencesFields.map((field, index) => (
                                        <li key={index} style={{marginBottom: '10px'}}>
                                            <strong>{field.name}</strong> - {field.organization}, {field.location}
                                            <br/>
                                            <span>{t('phone_colon')}: {field.phone}</span> | <span>{t('email_colon')}: {field.email}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}


                        {resumeData.personalData.weiterbildungskurseFields && resumeData.personalData.weiterbildungskurseFields.length > 0 && (
                            <div>
                                <h3>{t('additional_training')}</h3>
                                <ul>
                                    {resumeData.personalData.weiterbildungskurseFields && resumeData.personalData.weiterbildungskurseFields.map((field, index) => (
                                        <li key={index} style={{marginBottom: '10px'}}>
                                            <strong>{field.course}</strong>
                                            <br/>
                                            {field.startMonth && field.startYear
                                                ? `${new Date(0, field.startMonth - 1).toLocaleString(i18n.language, {
                                                    month: 'long',
                                                })} ${field.startYear}`
                                                : ''}
                                            {field.isCurrent
                                                ? ` - ${t('ongoing')}`
                                                : field.endMonth && field.endYear
                                                    ? ` - ${new Date(0, field.endMonth - 1).toLocaleString(i18n.language, {
                                                        month: 'long',
                                                    })} ${field.endYear}`
                                                    : ''}
                                            <br/>
                                            <em>{field.description}</em>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                <NotificationModal message={notification.message} isOpen={notification.isOpen}
                                   onClose={closeNotification}/>
            </div>
        </div>
    )
        ;
};

export default ResumeEditor;
