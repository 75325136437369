import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import ResumeEditor from './components/ResumeEditor';
import LandingPage from './pages/LandingPage';
import ProtectedRoute from './components/ProtectedRoute';
import './App.css';
import './i18n';
import ActivationPage from "./pages/ActivationPage";

function App() {
    return (
        <Router>
            <div className="App">
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/editor"
                        element={
                            <ProtectedRoute>
                                <ResumeEditor />
                            </ProtectedRoute>
                        }
                    />
                    <Route
                        path="/editor/:id" // Add this route for editing existing resumes
                        element={
                            <ProtectedRoute>
                                <ResumeEditor />
                            </ProtectedRoute>
                        }
                    />
                    <Route path="/auth/activate/:activationToken" element={<ActivationPage />} />
                </Routes>
            </div>
        </Router>
    );
}

export default App;
