import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faCheckCircle, faTimesCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import './ActivationPage.css'; // CSS für Styling
import logo from '../logo.png';

const ActivationPage = () => {
    const { activationToken } = useParams();
    const navigate = useNavigate();
    const [activationStatus, setActivationStatus] = useState("loading"); // Start mit Ladezustand
    const hasFetched = useRef(false); // Prevents multiple API calls

    useEffect(() => {
        const activateAccount = async () => {
            try {
                const response = await axios.get(`https://backend.cvblok.com/auth/activate/${activationToken}`);

                console.log("API Response:", response.data); // Debugging

                if (response.data.activatesuccess) {
                    setActivationStatus("success");
                } else {
                    setActivationStatus("error");
                }
            } catch (error) {
                console.error("Activation Error:", error.response?.data || error);
                setActivationStatus("error");
            }
        };

        if (activationToken && !hasFetched.current) {
            hasFetched.current = true; // Mark API call as done
            activateAccount();
        }
    }, [activationToken]);

    return (
        <div className="activation-container">
            <div className="activation-content">
                <img src={logo} alt="CVBlok Logo" className="activation-logo" />

                {/* Status-Nachricht basierend auf `activationStatus` */}
                {activationStatus === "success" ? (
                    <>
                        <h2>🇬🇧 Account successfully activated!</h2>
                        <h2>🇩🇪 Ihr Konto wurde erfolgreich aktiviert!</h2>
                        <h2>🇹🇷 Hesabınız başarıyla aktifleştirildi!</h2>
                    </>
                ) : activationStatus === "error" ? (
                    <>
                        <h2>🇬🇧 Invalid or expired activation link.</h2>
                        <h2>🇩🇪 Ungültiger oder abgelaufener Aktivierungslink.</h2>
                        <h2>🇹🇷 Geçersiz veya süresi dolmuş aktivasyon bağlantısı.</h2>
                    </>
                ) : (
                    <>
                        <h2>🇬🇧 Processing activation...</h2>
                        <h2>🇩🇪 Konto wird aktiviert...</h2>
                        <h2>🇹🇷 Hesabınız aktifleştiriliyor...</h2>
                    </>
                )}

                {/* Zurück-Button */}
                <button onClick={() => navigate('/')} className="activation-btn">
                    <FontAwesomeIcon icon={faHome} style={{ marginRight: '8px' }} />
                    CVBlok.com
                </button>
            </div>
        </div>
    );
};

export default ActivationPage;
