import { useState, useRef } from "react";
import { useTranslation } from "react-i18next";

// Define nationality options mapped by country codes
const nationalityOptions = {
    de: {
        DE: "Deutschland (DE)", TR: "Türkei (TR)", SY: "Syrien (SY)", AZ: "Aserbaidschan (AZ)", KZ: "Kasachstan (KZ)",
        KG: "Kirgisistan (KG)", IQ: "Irak (IQ)", IR: "Iran (IR)", AM: "Armenien (AM)", AF: "Afghanistan (AF)",
        GE: "Georgien (GE)", LY: "Libyen (LY)", TJ: "Tadschikistan (TJ)", UZ: "Usbekistan (UZ)", SO: "Somalia (SO)",
        PK: "Pakistan (PK)", NP: "Nepal (NP)", NG: "Nigeria (NG)", MT: "Malta (MT)", YE: "Jemen (YE)", SD: "Sudan (SD)",
        AL: "Albanien (AL)", EG: "Ägypten (EG)", DZ: "Algerien (DZ)", FR: "Frankreich (FR)", IT: "Italien (IT)",
        ES: "Spanien (ES)", NL: "Niederlande (NL)", PL: "Polen (PL)", GR: "Griechenland (GR)"
    },
    en: {
        DE: "Germany (DE)", TR: "Turkey (TR)", SY: "Syria (SY)", AZ: "Azerbaijan (AZ)", KZ: "Kazakhstan (KZ)",
        KG: "Kyrgyzstan (KG)", IQ: "Iraq (IQ)", IR: "Iran (IR)", AM: "Armenia (AM)", AF: "Afghanistan (AF)",
        GE: "Georgia (GE)", LY: "Libya (LY)", TJ: "Tajikistan (TJ)", UZ: "Uzbekistan (UZ)", SO: "Somalia (SO)",
        PK: "Pakistan (PK)", NP: "Nepal (NP)", NG: "Nigeria (NG)", MT: "Malta (MT)", YE: "Yemen (YE)", SD: "Sudan (SD)",
        AL: "Albania (AL)", EG: "Egypt (EG)", DZ: "Algeria (DZ)", FR: "France (FR)", IT: "Italy (IT)",
        ES: "Spain (ES)", NL: "Netherlands (NL)", PL: "Poland (PL)", GR: "Greece (GR)"
    },
    tr: {
        DE: "Almanya (DE)", TR: "Türkiye (TR)", SY: "Suriye (SY)", AZ: "Azerbaycan (AZ)", KZ: "Kazakistan (KZ)",
        KG: "Kırgızistan (KG)", IQ: "Irak (IQ)", IR: "İran (IR)", AM: "Ermenistan (AM)", AF: "Afganistan (AF)",
        GE: "Gürcistan (GE)", LY: "Libya (LY)", TJ: "Tacikistan (TJ)", UZ: "Özbekistan (UZ)", SO: "Somali (SO)",
        PK: "Pakistan (PK)", NP: "Nepal (NP)", NG: "Nijerya (NG)", MT: "Malta (MT)", YE: "Yemen (YE)", SD: "Sudan (SD)",
        AL: "Arnavutluk (AL)", EG: "Mısır (EG)", DZ: "Cezayir (DZ)", FR: "Fransa (FR)", IT: "İtalya (IT)",
        ES: "İspanya (ES)", NL: "Hollanda (NL)", PL: "Polonya (PL)", GR: "Yunanistan (GR)"
    },
    es: {
        DE: "Alemania (DE)", TR: "Turquía (TR)", SY: "Siria (SY)", AZ: "Azerbaiyán (AZ)", KZ: "Kazajistán (KZ)",
        KG: "Kirguistán (KG)", IQ: "Irak (IQ)", IR: "Irán (IR)", AM: "Armenia (AM)", AF: "Afganistán (AF)",
        GE: "Georgia (GE)", LY: "Libia (LY)", TJ: "Tayikistán (TJ)", UZ: "Uzbekistán (UZ)", SO: "Somalia (SO)",
        PK: "Pakistán (PK)", NP: "Nepal (NP)", NG: "Nigeria (NG)", MT: "Malta (MT)", YE: "Yemen (YE)", SD: "Sudán (SD)",
        AL: "Albania (AL)", EG: "Egipto (EG)", DZ: "Argelia (DZ)", FR: "Francia (FR)", IT: "Italia (IT)",
        ES: "España (ES)", NL: "Países Bajos (NL)", PL: "Polonia (PL)", GR: "Grecia (GR)"
    }
};

const NationalityDropdown = ({ selectedNationalities = [], setResumeData, setUnsavedChanges }) => {
    const { t, i18n } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Toggle dropdown visibility
    const handleToggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    // Handle selection change
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;

        setResumeData((prevData) => {
            const currentNationalities = Array.isArray(prevData.personalData.nationality)
                ? prevData.personalData.nationality
                : [];

            const updatedNationalities = checked
                ? [...currentNationalities, value] // Store only country code
                : currentNationalities.filter((nat) => nat !== value);

            return {
                ...prevData,
                personalData: { ...prevData.personalData, nationality: updatedNationalities },
            };
        });

        setUnsavedChanges(true);
    };

    return (
        <div className="custom-dropdown" ref={dropdownRef}>
            <div className="dropdown-header" onClick={handleToggleDropdown}>
                {selectedNationalities.length > 0
                    ? selectedNationalities.map((code) => nationalityOptions[i18n.language][code] || code).join(", ")
                    : t("select_nationality")}
                <span className="dropdown-arrow">▼</span>
            </div>
            {isOpen && (
                <div className="dropdown-list">
                    {Object.entries(nationalityOptions[i18n.language]).map(([code, label]) => (
                        <label key={code} className="dropdown-item">
                            <input
                                type="checkbox"
                                value={code}
                                checked={selectedNationalities.includes(code)}
                                onChange={handleCheckboxChange}
                            />
                            {label}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );
};

export default NationalityDropdown;
export { nationalityOptions };
