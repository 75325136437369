import React from 'react';
import './NotificationModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const NotificationModal = ({ message, isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="notification-modal">
            <div className="notification-content">
                <span className="close-btn" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimesCircle} />
                </span>
                <br/>
                <p>{message}</p>
                <br/>
                <button onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

export default NotificationModal;
